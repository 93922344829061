import $ from 'jquery'
import 'jquery-migrate'
import jqueryBridget from 'jquery-bridget'
import Masonry from 'masonry-layout'
import 'angular'
import 'picturefill'
import 'screenfull'
import 'simplebar/dist/simplebar.css'

import './custom-event-polyfill'
import './classList'
import './iframeResizer'
import './jquery.bxslider'
import './jquery.cookie'
import './jquery.easytabs.min'
import './jquery.event.move'
import './jquery.focuspoint'
import './jquery.hashchange'
import './jquery.twentytwenty'
import './jquery.validate'
import './jquery.validate.unobtrusive'
import './jquery.visible.min'
import './jsmpg'
import './modernizr.custom'
import './ScrollMagic'
import './swipegestures'
import './jquery-ui'

jqueryBridget('masonry', Masonry, $)
