/**
 * Flip counter jQuery pluggin
 * @author Konstantin Spirichev
 */

(function (window, document, $, undefinded) {
	'use strict';

	var FlipCounter = function (options) {
		var defaults = {
			start: 0,
			end: 10,
			topText: "",
			bottomText: "",
			interval: 1000,
			actionClass: "loaded"
		},
            options = options || {};

		this.options = $.extend({}, defaults, options);
		this.container = $("<div />", {
			"class": "fcnn"
		});
	};

	FlipCounter.prototype._init = function () {

		this.decremental = this.options.start > this.options.end;

		this._build();
		this.start();

	};
	FlipCounter.prototype._build = function () {
		var self = this,
            container = this.container,
            options = this.options,
            cardsTop = "",
            cardsBottom = "",

            renderTopCard = function (index) {
            	var cardIndexClass = i == options.end ? "" : " card-" + i,
                    text = "<div class='card card-top " + options.actionClass + cardIndexClass + "'>";

            	if (options.topText && !options.alias[i]) {
            		text += "<div class='card-top-text'>" + options.topText + "</div>"
            	} else if (options.alias && options.alias[i]) {
            		text += "<div class='card-top-text'>" + options.aliasTop + "</div>"
            	}

            	if (options.alias && options.alias[i]) {
            		text += "<div class='item item-alias'>" + options.alias[i] + "</div></div>";
            	} else {
            		text += "<div class='item'>" + i + "</div></div>";
            	}
            	return text;
            },
            renderBottomCard = function (index) {
            	var cardIndexClass = i == options.start ? "" : " card-" + (self.decremental ? i + 1 : i - 1),
                    text = "<div class='card card-bottom " + cardIndexClass + (i == options.start ? "" : " " + options.actionClass) + "'>";

            	if (options.bottomText && !options.alias[i]) {
            		text += "<div class='card-bottom-text'>" + options.bottomText + "</div>"
            	}

            	if (options.alias && options.alias[i]) {
            		text += "<div class='item item-alias'>" + options.alias[i] + "</div></div>";
            	} else {
            		text += "<div class='item'>" + i + "</div></div>";
            	}
            	return text;
            };

		if (typeof options.start === "number" && typeof options.end === "number") {

			//cardsTop += "<div class='fcn-top'>";

			if (this.decremental) {
				for (var i = options.end; i <= options.start; i++) {
					cardsTop += renderTopCard(i);
				}
			} else {
				for (var i = options.end; i >= options.start; i--) {
					cardsTop += renderTopCard(i);
				}
			}

			//cardsTop+="</div>";

			//cardsBottom+="<div class='fcn-bottom'>";

			if (this.decremental) {
				for (var i = options.start; i >= options.end; i--) {
					cardsBottom += renderBottomCard(i);
				}
			} else {
				for (var i = options.start; i <= options.end; i++) {
					cardsBottom += renderBottomCard(i);
				}
			}

			//cardsBottom += "</div>";

			container.html(cardsTop + cardsBottom);
			$(this.el).append(container);
		}
	};
	FlipCounter.prototype.restart = function (data) {
		this.container.html("");
		if (typeof data === "object") {
			this.options = $.extend({}, this.options, data);
		}
		this._init();
	};
	FlipCounter.prototype.flip = function (index) {
		var self = this;
		this.container.find(".card-" + index).removeClass(this.options.actionClass)
		/*.one("transitionend webkitTransitionEnd", function(){
			$(this).not(".card-bottom:last-child").hide();
		})*/
	};
	FlipCounter.prototype.start = function () {
		var self = this;
		var run = function (index) {
			setTimeout(function () {
				self.flip(index);
				if (self.decremental) {
					index--
					if (index > self.options.end)
						run(index);
				} else {
					index++
					if (index < self.options.end)
						run(index);
				}

			}, self.options.interval);
		};


		run(self.options.start);

	};

	$.fn.flipCounter = function (arg, parameters) {
		var options = typeof arg === "object" ? arg : {},
            method = typeof arg === "string" ? arg : null,
            methodArgument = parameters;
		this.each(function (index, item) {
			var flipCounter = $(item).data("flipcounter") || new FlipCounter(options);
			if (!flipCounter.el) {
				flipCounter.el = item;
				$(item).data("flipcounter", flipCounter);
				flipCounter._init();
			} else if (method) {
				flipCounter[method](methodArgument);
			}


		})
		return this;
	};

})(window, document, jQuery);
