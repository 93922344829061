(function($){

	$.fn.twentytwenty = function(_options) {
	var options = $.extend({default_offset_pct: 0.5, orientation: 'horizontal'}, _options);
	return this.each(function() {

		var sliderPct = options.default_offset_pct;
		var container = $(this);
		var sliderOrientation = options.orientation;
		var beforeDirection = (sliderOrientation === 'vertical') ? 'down' : 'left';
		var afterDirection = (sliderOrientation === 'vertical') ? 'up' : 'right';

		var beforeImg = container.find(".two-slides-item:first");
		var afterImg = container.find(".two-slides-item:last");
		container.append("<div class='two-slides-handle'></div>");
		var slider = container.find(".two-slides-handle");
		slider.append("<span class='" + beforeDirection + "-arrow'></span>");
		slider.append("<span class='" + afterDirection + "-arrow'></span>");
		beforeImg.addClass("slide-before");
		afterImg.addClass("slide-after");
		var handleOffset = 0;

		var calcOffset = function(dimensionPct) {
			var w = beforeImg.width();
			var h = beforeImg.height();
			return {
				w: w+"px",
				h: h+"px",
				cw: (dimensionPct*w)+"px",
				ch: (dimensionPct*h)+"px"
			};
		};

		var adjustContainer = function(offset) {
      		if (sliderOrientation === 'vertical') {
      			beforeImg.css("clip", "rect(0,"+offset.w+","+offset.ch+",0)");
      		}
      		else {
				beforeImg.css("clip", "rect(0,"+offset.cw+","+offset.h+",0)");
    		}
			container.css("height", offset.h);
		};

		var adjustSlider = function (pct) {
			var offset = calcOffset(pct);
			if (parseInt(offset.h) == 500 && parseInt(offset.cw) < 135) {
				offset.cw = 135 + "px";
			} else if (parseInt(offset.h) == 400 && parseInt(offset.cw) < 110) {
				offset.cw = 110 + "px";
			} else if (parseInt(offset.h) == 300 && parseInt(offset.cw) < 80) {
				offset.cw = 80 + "px";
			}
			slider.css((sliderOrientation === "vertical") ? "top" : "left", (sliderOrientation === "vertical") ? offset.ch : offset.cw);
			adjustContainer(offset);
		};

		$(window).on("resize.twentytwenty", function (e) {
      		adjustSlider(sliderPct);

      		var deckOffset = container.offset().top;
      		var windowScrollTop = $(window).scrollTop();
      		if (windowScrollTop >= deckOffset - 200) {
      			setTimeout(function () {
      				$(window).trigger("animate.twentytwenty", 0.65);
      			}, 800);
      		}
		});

		$(window).on("animate.twentytwenty", function (e, sliderOffset) {
      		var deckOffset = container.offset().top;
      		var windowScrollTop = $(window).scrollTop();
      		if (windowScrollTop >= deckOffset - 200) {
      			var sliderP = sliderOffset ? sliderOffset : sliderPct;

      			var offset = calcOffset(sliderP);
      			slider.css("left", offset.cw);
      			beforeImg.css("clip", "rect(0px," + offset.cw + "," + offset.h + ",0px)");
      		}
		});

		var offsetX = 0;
		var offsetY = 0;
		var imgWidth = 0;
		var imgHeight = 0;
      
		slider.on("movestart", function (e) {
			if (((e.distX > e.distY && e.distX < -e.distY) || (e.distX < e.distY && e.distX > -e.distY)) && sliderOrientation !== 'vertical') {
				e.preventDefault();
			}
			else if (((e.distX < e.distY && e.distX < -e.distY) || (e.distX > e.distY && e.distX > -e.distY)) && sliderOrientation === 'vertical') {
				e.preventDefault();
			}
			container.addClass("active");
			container.removeClass("slider-loading");

			offsetX = container.offset().left;
			offsetY = container.offset().top;
			imgWidth = beforeImg.width(); 
			imgHeight = beforeImg.height();

			if (parseInt(imgHeight) == 500) {
				handleOffset = 100;
			} else if (parseInt(imgHeight) == 400) {
				handleOffset = 89;
			} else if (parseInt(imgHeight) == 300) {
				handleOffset = 65;
			}
		});

		slider.on("moveend", function(e) {
      		container.removeClass("active");
      		container.addClass("slider-loading");
		});

		slider.on("move", function(e) {
			if (container.hasClass("active")) {
				var diff = e.pageX - slider.offset().left; // counting handle width into position calculation to prevent 'jumping' of the slide on movestart
				if (diff > 0 && diff < handleOffset) {
					diff = handleOffset - diff;
				} else if (diff < 0) {
					diff = handleOffset;
				} else {
					diff = 0;
				}

				sliderPct = (sliderOrientation === 'vertical') ? (e.pageY - offsetY) / imgHeight : (e.pageX + diff - offsetX) / imgWidth;
				if (sliderPct < 0) {
					sliderPct = 0;
				}
				if (sliderPct > 1) {
					sliderPct = 1;
				}
				adjustSlider(sliderPct);
			}
		});

		container.find("img").on("mousedown", function(event) {
		event.preventDefault();
		});

		$(window).trigger("resize.twentytwenty");
	});
	};
})(jQuery);
